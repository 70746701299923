import {
  Header,
  UserStatus,
} from '@customer-portal/constants';
import { getMergedAndSortedRoles } from '@customer-portal/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
// helpers
import { Parser } from 'json2csv';
import moment from 'moment';
import React, {
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// styles
import * as styled from '../../assets/css/CustomerPortalAnalytics';
import { CPTableContainer } from '../../assets/css/Table';
// components
import BasicHero from '../../components/BasicHero/CustomerPortal-BasicHero';
import Button from '../../components/Button/Button';
// Google Analytics
import {
  CustomerPortalGoogleAnalyticsEvent,
  CustomerPortalGoogleAnalyticsPageView,
} from '../../components/CustomerPortal-GoogleAnalytics';
import CustomerPortalLoader from '../../components/CustomerPortal-Loader';
import CustomerPortalPagination from '../../components/CustomerPortal-Pagination';
import { USER_ROLE_TO_DISPLAY_NAME } from '../../constants/roles.constants';
import { ANALYTICS } from '../../constants/telemetry.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import Container from './../../components/CustomerPortal-New-Container';
// Constants
import { BACKEND_HOST_NAME } from './../../constants/network.constants';
// context

const CustomerPortalPageAnalytics = (props: any) => {
  const { getAccessToken } = useAuth();
  const docsPerPage = 10;
  const [ cpStats, setCPStats ] = useState({
    totalActiveCustomers: 0,
    totalInvitedCustomers: 0,
    totalMultiAccountUsers: 0,
    totalUsers: 0,
    uiPathUsers: 0,
    customerUsers: 0,
    weeklyNotification: 0,
    totalActiveUsers: 0,
    totalPendingUsers: 0,
    totalDisabledUsers: 0,
    activeUiPathUsers: 0,
  });

  const [ customers, setCustomers ] = useState([]);
  const [ activeUipathUsers, setActiveUipathUsers ] = useState([]);
  const [ kbMonthlyDownloadsData, setKbMonthlyDownloadsData ] = useState({
    data: [],
    totalDownloads: 0,
    totalRatings: 0,
    totalAvgRatings: 0,
  });

  const [ collabData, setCollabData ] = useState([]);
  const [ kbMonthly, setKbMonthly ] = useState([]);
  const [ dataLoaded, setDataLoaded ] = useState(false);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ currentPageActiveUipathUsers, setCurrentPageActiveUipathUsers ] = useState(1);
  const [ currentPageKBD, setCurrentPageKBD ] = useState(1);

  const startKBD = (currentPageKBD - 1) * docsPerPage;
  const endKBD = currentPageKBD * docsPerPage;
  const statsData = [
    {
      title: 'Total Number of Users',
      value: cpStats.totalUsers,
    },
    {
      title: 'Total Uipath Users',
      value: cpStats.uiPathUsers,
    },
    {
      title: 'Total Customer Users',
      value: cpStats.customerUsers,
    },
    {
      title: 'Total Multi Account Users',
      value: cpStats.totalMultiAccountUsers,
    },
    {
      title: 'Weekly Notifications',
      value: cpStats.weeklyNotification,
    },
    {
      title: 'Active Accounts',
      value: cpStats.totalActiveCustomers,
    },
    {
      title: 'Invited Accounts',
      value: cpStats.totalInvitedCustomers,
    },
  ];

  useTrackPageViewEvent(ANALYTICS);

  /* Lifecycle */
  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Analytics');
    getAnalyticsData();
  }, []);

  const handlePaginationClick = async (page: number) => {
    const adoptionData = await axios.get(
      `${BACKEND_HOST_NAME}/api/v1/analytics/getCustomerAdoptionData`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          [Header.AUTH_TYPE]: getAuthType(),
        },
        params: {
          top: docsPerPage,
          skip: (page - 1) * docsPerPage,
        },
      }
    );
    setCustomers(adoptionData?.data?.data);
    setCurrentPage(page);
  };

  const handlePaginationClickActiveUiPathUsers = async (page: number) => {
    const adoptionData = await axios.get(
      `${BACKEND_HOST_NAME}/api/v1/analytics/getActiveUiPathUserAdoptionData`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          [Header.AUTH_TYPE]: getAuthType(),
        },
        params: {
          top: docsPerPage,
          skip: (page - 1) * docsPerPage,
        },
      }
    );
    setActiveUipathUsers(adoptionData?.data?.data);
    setCurrentPageActiveUipathUsers(page);
  };

  const handlePaginationClickKBD = (page: number) => {
    setCurrentPageKBD(page);
  };

  const handleDownloadActiveUiPathUsersCSVClick = async () => {
    const totalAdoptionData = await axios.get(
      `${BACKEND_HOST_NAME}/api/v1/analytics/getActiveUiPathUserAdoptionData`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          [Header.AUTH_TYPE]: getAuthType(),
        },
      }
    );

    CustomerPortalGoogleAnalyticsEvent(
      'Analytics',
      'Button Click',
      'Download CSV Active UiPath Users Adoption'
    );

    const json2csvParser = new Parser({
      transforms: [
        item => ({
          ...item,
          name: item.name,
          email: item.email,
          roles: getMergedAndSortedRoles(item.roles)
            .map((r) => USER_ROLE_TO_DISPLAY_NAME[r])
            .join(', '),
          isActive: item.status === UserStatus.ACTIVE ? 'Yes' : 'No',
          createdAt: moment(item.createdAt).format('MMM DD, YYYY HH:mm'),
        }),
      ],
      fields: [
        {
          label: 'Full Name',
          value: 'name',
        },
        {
          label: 'Email',
          value: 'email',
        },
        {
          label: 'Roles Assigned',
          value: 'roles',
        },
        {
          label: 'Date Created/Added',
          value: 'createdAt',
        },
        {
          label: 'Active',
          value: 'isActive',
        },
      ],
    });
    const csv = json2csvParser.parse(totalAdoptionData?.data?.data);
    const element = document.createElement('a');
    const file = new Blob([ '\ufeff' + csv ], { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    element.download = `Uipath_Users_Adoption.csv`;
    document.body.appendChild(element);
    element.click();
  };

  const handleDownloadCSVClick = async () => {
    const totalAdoptionData = await axios.get(
      `${BACKEND_HOST_NAME}/api/v1/analytics/getAllCustomerAdoptionData`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          [Header.AUTH_TYPE]: getAuthType(),
        },
      }
    );

    CustomerPortalGoogleAnalyticsEvent(
      'Analytics',
      'Button Click',
      'Download CSV Customer Adoption'
    );
    const json2csvParser = new Parser({
      transforms: [
        item => ({
          ...item,
          customer: item.name,
          longId: item.id,
          active: item.activeUsers,
          pending: item.pendingUsers,
          disabled: item.disabledUsers,
          geo: item.geo,
          country: item.country,
          region: item.region,
          segmentation: item.segmentation,
          segmentation_cs_3_0: item.segmentation_cs_3_0,
          type: item.maintenanceFlag,
        }),
      ],
      fields: [
        {
          label: 'Customer',
          value: 'customer',
        },
        {
          label: 'ID long',
          value: 'longId',
        },
        {
          label: 'Active',
          value: 'active',
        },
        {
          label: 'Pending',
          value: 'pending',
        },
        {
          label: 'Disabled',
          value: 'disabled',
        },
        {
          label: 'Geo',
          value: 'geo',
        },
        {
          label: 'Country',
          value: 'country',
        },
        {
          label: 'Region',
          value: 'region',
        },
        {
          label: 'Segmentation',
          value: 'segmentation',
        },
        {
          label: 'Segmentation 3.0',
          value: 'segmentation_cs_3_0',
        },
        {
          label: 'Premium/Premium Plus/Standard',
          value: 'type',
        },
      ],
    });
    const csv = json2csvParser.parse(totalAdoptionData?.data?.data);
    const element = document.createElement('a');
    const file = new Blob([ '\ufeff' + csv ], { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    element.download = `Customer_Adoption_Analytics.csv`;
    document.body.appendChild(element);
    element.click();
  };

  const handleDownloadCSVClickKBD = () => {
    CustomerPortalGoogleAnalyticsEvent(
      'Analytics',
      'Button Click',
      'Download CSV Knowledge Base Downloads'
    );
    const json2csvParser = new Parser({
      transforms: [
        item => ({
          ...item,
          asset_name: `${item.name}`,
          category: `${item.category_name}`,
          subcategory: `${item.subcategory_name}`,
          downloads: `${item.count}`,
          ratings: `${item.ratings}`,
          avg_rating: `${item.avg_rating * 100} %`,
        }),
      ],
      fields: [
        {
          label: 'Asset Name',
          value: 'asset_name',
        },
        {
          label: 'Category',
          value: 'category',
        },
        {
          label: 'Subcategory',
          value: 'subcategory',
        },
        {
          label: '# of Downloads',
          value: 'downloads',
        },
        {
          label: '# of Ratings',
          value: 'ratings',
        },
        {
          label: 'Avg Rating',
          value: 'avg_rating',
        },
      ],
    });
    const csv = json2csvParser.parse(kbMonthlyDownloadsData.data);
    const element = document.createElement('a');
    const file = new Blob([ '\ufeff' + csv ], { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    element.download = `Knowledge_Base_Downloads.csv`;
    document.body.appendChild(element);
    element.click();
  };

  const getAnalyticsData = async () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        [Header.AUTH_TYPE]: getAuthType(),
      },
    };
    axios
      .all([
        axios.get(`${BACKEND_HOST_NAME}/api/v1/analytics/getCPStats`, headers),
        axios.get(
          `${BACKEND_HOST_NAME}/api/v1/analytics/getCustomerAdoptionData`,
          headers
        ),
        axios.get(
          `${BACKEND_HOST_NAME}/api/v1/analytics/getActiveUiPathUserAdoptionData`,
          {
            ...headers,
            params: {
              top: docsPerPage,
              skip: 0,
            },
          }
        ),
        axios.get(
          `${BACKEND_HOST_NAME}/api/v1/analytics/getCollabAssetsUploaded`,
          headers
        ),
        axios.get(
          `${BACKEND_HOST_NAME}/api/v1/analytics/getDownloadsPerAsset`,
          headers
        ),
        axios.get(
          `${BACKEND_HOST_NAME}/api/v1/analytics/getKbDownloadsMonthly`,
          headers
        ),
      ])
      .then(
        axios.spread(async function(
          cpStatsResponse,
          customerAdoptionDataResponse,
          activeUipathUsersAdoptionDataResponse,
          collabDataResponse,
          downloadsPerAssetsResponse,
          monthlyKBDownloadsResponse
        ) {
          setCPStats(cpStatsResponse.data.data);
          setCustomers(customerAdoptionDataResponse?.data?.data ?? []);
          setActiveUipathUsers(
            activeUipathUsersAdoptionDataResponse?.data?.data ?? []
          );
          setCollabData(collabDataResponse.data);
          setKbMonthlyDownloadsData(downloadsPerAssetsResponse.data);
          setKbMonthly(monthlyKBDownloadsResponse.data);
          setDataLoaded(true);
        })
      );
  };

  const getYearMonth = (obj: any) => {
    const x =
      moment()
        .month(obj._id.month - 1)
        .format('MMM') +
      '-' +
      moment()
        .year(obj._id.year)
        .format('YY');
    return x;
  };

  type AnalyticsBarChartProps = {
    chartData: any[];
    dataKey: string;
  };

  const AnalyticsBarChart = (props: AnalyticsBarChartProps) => (
    <ResponsiveContainer
      width="99%"
      aspect={3}
      debounce={300}>
      <BarChart
        width={500}
        height={300}
        data={props.chartData}
        margin={{
          top: 20,
          right: 30,
          left: 10,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={getYearMonth} />
        <YAxis dataKey={props.dataKey} />
        <Tooltip />
        <Legend />
        <Bar
          dataKey={props.dataKey}
          fill="#0067DF">
          <LabelList
            dataKey={props.dataKey}
            position="top" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );

  return (
    <>
      <Helmet>
        <title>Customer Portal | Analytics</title>
      </Helmet>

      <styled.AnalyticsWrapper>
        <BasicHero title="Analytics" />

        <Container>
          {dataLoaded && (
            <div data-testid="Container">
              <h3>Customer Portal User Stats</h3>

              <div className="flex-grid">
                {statsData.map((stat: any) => (
                  <div
                    className="col-heading"
                    key={stat.title}>
                    <p className="Analytics__Data-Heading">{stat.title}</p>
                    <p className="Analytics__Data-Values">{stat.value}</p>
                  </div>
                ))}
              </div>

              <div className="Analytics__Container">
                <h3 className="marginBottomh3">
                  {' '}
                  Customer Adoption
                  {customers.length > 0 && (
                    <Button
                      onClick={handleDownloadCSVClick}
                      text="Download CSV"
                      className="Download-ButtonCSV"
                    />
                  )}
                </h3>

                <CPTableContainer className="Table__Normal">
                  <Table
                    size="small"
                    aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Customer / ID Long</TableCell>
                        <TableCell align="center">
                          <span className="users__heading">
                            <br />
                            Users
                            <br />
                          </span>
                          <div className="user__stats">
                            <div className="user__statsItem">Active</div>
                            <div className="user__statsItem">Pending</div>
                            <div className="user__statsItem">Disabled</div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          Geo / Country / Region
                        </TableCell>
                        <TableCell align="left">Segmentation</TableCell>
                        <TableCell align="left">Segmentation 3.0</TableCell>
                        <TableCell align="left">Maintenance</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customers.map((customer: any) => (
                        <TableRow key={customer._id}>
                          <TableCell align="left">
                            <span className="customer__name">
                              {customer.name}
                            </span>
                            <br />
                            <span className="customer__id">{customer.id}</span>
                          </TableCell>
                          <TableCell align="center">
                            <div className="user__stats">
                              <div className="user__statsItem">
                                {customer.activeUsers}
                              </div>
                              <div className="user__statsItem">
                                {customer.pendingUsers}
                              </div>
                              <div className="user__statsItem">
                                {customer.disabledUsers}
                              </div>
                            </div>
                          </TableCell>

                          <TableCell align="left">
                            {customer.geo}
                            {' '}
/
                            {customer.country}
                            {' '}
/
                            {' '}
                            {customer.region}
                          </TableCell>
                          <TableCell align="left">
                            {customer.segmentation}
                          </TableCell>
                          <TableCell align="left">
                            {customer.segmentation_cs_3_0}
                          </TableCell>
                          <TableCell align="left">
                            {customer.maintenanceFlag}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell>
                          <b>Total</b>
                        </TableCell>

                        <TableCell align="center">
                          <div className="user__stats">
                            <div className="user__statsItem">
                              <b>{cpStats.totalActiveUsers}</b>
                            </div>
                            <div className="user__statsItem">
                              <b>{cpStats.totalPendingUsers}</b>
                            </div>
                            <div className="user__statsItem">
                              {' '}
                              <b>{cpStats.totalDisabledUsers}</b>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <CustomerPortalPagination
                    activePage={currentPage}
                    handleClick={handlePaginationClick}
                    numPagesBeforeEllipses={5}
                    numResultsPerPage={docsPerPage}
                    numResults={cpStats.totalActiveCustomers}
                    dataTestIdRightButton="Analytics__AdoptionRightButton"
                  />
                </CPTableContainer>
              </div>

              <div className="Analytics__Container">
                <h3 className="marginBottomh3">
                  {' '}
                  UiPath Users Adoption
                  {activeUipathUsers.length > 0 && (
                    <Button
                      onClick={handleDownloadActiveUiPathUsersCSVClick}
                      text="Download CSV"
                      className="Download-ButtonCSV"
                    />
                  )}
                </h3>

                <CPTableContainer className="Table__Normal">
                  <Table
                    size="small"
                    aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Roles Assigned</TableCell>
                        <TableCell>Date Created/Added</TableCell>
                        <TableCell>Active</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activeUipathUsers.map((user: any) => (
                        <TableRow key={Math.random()}>
                          <TableCell align="left">
                            <span className="customer__name">
                              {user.name}
                            </span>
                            <br />
                          </TableCell>
                          <TableCell align="left">
                            {user.email}
                          </TableCell>
                          <TableCell align="left">
                            <div>
                              <ul className="user_roles">
                                {getMergedAndSortedRoles(user.roles)
                                  .map((r) => USER_ROLE_TO_DISPLAY_NAME[r] ?? '-')
                                  .map((r) => <li key={r}>{r}</li>)}
                              </ul>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            {moment(user.createdAt).format('MMM DD, YYYY HH:mm')}
                          </TableCell>
                          <TableCell align="left">
                            {user.status === UserStatus.ACTIVE ? 'Yes' : 'No'}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell>
                          <b>Total</b>
                        </TableCell>

                        <TableCell align="center">
                          <div className="user__stats">
                            <b>{cpStats.activeUiPathUsers}</b>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <CustomerPortalPagination
                    activePage={currentPageActiveUipathUsers}
                    handleClick={handlePaginationClickActiveUiPathUsers}
                    numPagesBeforeEllipses={5}
                    numResultsPerPage={docsPerPage}
                    numResults={cpStats.activeUiPathUsers}
                    dataTestIdRightButton="Analytics__ActiveUiPathUsersRightButton"
                  />
                </CPTableContainer>
              </div>

              <div className="Analytics__Container">
                <h3 className="marginBottomh3">
                  Knowledge Base Downloads
                  {kbMonthlyDownloadsData.data.length > 0 && (
                    <Button
                      onClick={handleDownloadCSVClickKBD}
                      text="Download CSV"
                      className="Download-ButtonCSV"
                    />
                  )}
                </h3>

                <CPTableContainer className="Table__Normal">
                  <Table
                    size="small"
                    aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Asset Name</TableCell>
                        <TableCell align="left">Category</TableCell>
                        <TableCell align="left">Subcategory</TableCell>
                        <TableCell align="left"># of Downloads</TableCell>
                        <TableCell align="left"># of Ratings</TableCell>
                        <TableCell align="center">Avg Rating</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {kbMonthlyDownloadsData.data
                        .slice(startKBD, endKBD)
                        .map((kb: any) => (
                          <TableRow key={kb._id}>
                            <TableCell align="left">
                              {kb.name.substring(0, 60)}
                            </TableCell>
                            <TableCell align="left">
                              {kb.category_name}
                            </TableCell>
                            <TableCell align="left">
                              {kb.subcategory_name}
                            </TableCell>
                            <TableCell align="left">{kb.count}</TableCell>
                            <TableCell align="left">{kb.ratings}</TableCell>
                            <TableCell align="center">
                              {(kb.avg_rating * 100).toFixed(0).toString() +
                                '%'}
                            </TableCell>
                          </TableRow>
                        ))}
                      <TableRow>
                        <TableCell>
                          <b>Total</b>
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell align="left">
                          <b>{kbMonthlyDownloadsData.totalDownloads}</b>
                        </TableCell>
                        <TableCell align="left">
                          <b>{kbMonthlyDownloadsData.totalRatings}</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>{kbMonthlyDownloadsData.totalAvgRatings}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <CustomerPortalPagination
                    activePage={currentPageKBD}
                    handleClick={handlePaginationClickKBD}
                    numPagesBeforeEllipses={5}
                    numResultsPerPage={docsPerPage}
                    numResults={kbMonthlyDownloadsData.data.length}
                  />
                </CPTableContainer>
              </div>
              <div className="Analytics__Container">
                <AnalyticsBarChart
                  chartData={kbMonthly}
                  dataKey="downloads" />
              </div>
              <div className="Analytics__Container">
                <h3 className="marginBottomh3">Collaboration Space Uploads</h3>
                <AnalyticsBarChart
                  chartData={collabData}
                  dataKey="uploads" />
              </div>
            </div>
          )}

          {!dataLoaded && (
            <styled.analyticsLoader>
              <div>
                <CustomerPortalLoader />
              </div>
            </styled.analyticsLoader>
          )}
        </Container>
      </styled.AnalyticsWrapper>
    </>
  ); // End of return
};

export default CustomerPortalPageAnalytics;
